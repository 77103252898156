<template>
    <div class="profile animate__animated animate__fadeInDown">
        <div class="user-box">
            <avatar v-if="userinfo && userinfo.phone" :txt="userinfo.phone" />
            <div class="right">
                <div class="phone">
                    {{userinfo.phone}} 
                </div>
                <div class="down">
                    <span>{{userinfo.inviteCode}}</span>
                    <a-icon type="copy" @click="copy(userinfo.inviteCode)" />
                </div>
            </div>
        </div>
        <div class="assets-box">
            <div class="flex top">
                <span class="top-title">{{$t('profile.fixedAsset')}}</span>
                <span>{{(userinfo.total_assets)}}{{$t('common.currency')}}</span>
            </div>
            <div class="row-wrapper">
            <a-row type="flex" :gutter="16">
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="euro" />
                        <span class="txt">{{$t('profile.labels.available')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.balance/1000000}}
                    </div>
                </a-col>
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="hourglass" />
                        <span class="txt">{{$t('profile.labels.available')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.freezed_amount}}
                    </div>
                </a-col>
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="gift" />
                        <span class="txt">{{$t('profile.labels.shoppingbonus')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.shopping_bonus}}
                    </div>
                </a-col>
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="apartment" />
                        <span class="txt">{{$t('profile.labels.teamcommission')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.share_bonus}}
                    </div>
                </a-col>
                
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="trophy" />
                        <span class="txt">{{$t('profile.labels.todayprofit')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.today_profit}}
                    </div>
                </a-col>
                
                <a-col class="subcard" :span="12">
                    <div class="sub-title">
                        <a-icon type="carry-out" />
                        <span class="txt">{{$t('profile.labels.withdrawled')}}</span>
                    </div>
                    <div class="amount">
                        {{userinfo.withdrawled_total}}
                    </div>
                </a-col>
            </a-row>
            </div>
            
    
        </div>
        <div class="box flex nav-bar invite" @click="invite">
            <div class="icon">
                <a-icon type="user-add" />
            </div>
            <div class="title">
                {{$t('profile.titles.invite')}}
            </div>
            <a-icon type="caret-right" />
        </div>
        <div class="box flex nav-bar" @click="$router.push({name:'team'})">
            <div class="icon">
                <a-icon type="team" />
            </div>
            <div class="title">
                {{$t('profile.titles.team')}}
            </div>
            <a-icon type="caret-right" />
        </div>
        <div class="box flex nav-bar account-security" @click="$router.push({name:'security'})">
            <div class="icon">
                <a-icon type="safety-certificate" />
            </div>
            <div class="title">
                {{$t('profile.titles.accountSecurity')}}
            </div>
            <a-icon type="caret-right" />
        </div>
        <div class="box flex nav-bar language" @click="changeLang">
            <div class="icon">
                <img src="@/assets/svgs/language_en_icon.svg" alt="">
            </div>
            <div class="title">
                {{$t('profile.titles.language')}}
            </div>
            <div>
                <img :src="'/statics/imgs/langs/'+$store.state.lang+'.png'" alt="">
                <a-icon type="caret-right" />
            </div>
        </div>
        <div class="box flex nav-bar about-us" @click="$router.push({name:'article',params:{id:5}})">
            <div class="icon">
                <a-icon type="tag" />
            </div>
            <div class="title">
                {{$t('profile.titles.aboutUs')}}
            </div>
            <a-icon type="caret-right" />
        </div>
        <div class="box flex nav-bar logout" @click="logout()">
            <div class="icon">
                <a-icon type="logout" />
            </div>
            <div class="title">
                {{$t('profile.titles.logout')}}
            </div>
            <a-icon type="caret-right" />
        </div>
    </div>
</template>
<script>
import Avatar from "../components/Avatar.vue";
export default {
    name: "Profile",
    data() {
        return {
            userinfo: {},
            // levels: ["Citizen","Shareholder","Director","Group","Global Community","Global Node"],
        };
    },
    components: {
        Avatar
    },
    computed: {
        
    },
    mounted() {
        this.getUserinfo();
    },
    methods: {
        logout(){
            let _this = this
            this.$http.call(this.$http.api.logout).then(function(res){
                console.log(res)
                
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.$store.commit("setToken","");
                _this.$store.commit("setUserinfo",{
                    address: '', balance: 0.00, node_level: 0
                });
                _this.$router.push({name:'login'});
            });
            
        },
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res.data.data);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        goGame(game){
            // window.open(game.url, "_blank");
            window.location.href = game.url;
        },
        moreGame(){
            this.$message.success(this.$t('profile.moreGameTips'));
        },
        copy(data){
            let oInput = document.createElement('input');
            oInput.value = data;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            console.log(oInput.value)
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success(this.$t('common.copySuccess'));
            oInput.remove();
        },
        invite(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowInviteBox',true);
        },
        changeLang(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowLangBox',true);
        },
    },
};
</script>
<style lang="less" scoped>
.profile {
    width: 100%;
    padding: 15px;
    background: url("../assets/imgs/profile_bg.jpg") no-repeat;
    background-position: top center;
    background-size: contain;
    .user-box{
        padding: 30px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: @white;
        .right{
            padding-left: 15px;
            text-align: left;
            .phone{
                font-size: 1.2rem;
            }
        }
    }
    .assets-box{
        background: @bg-second;
        border-radius: 16px;
        color: @white;
        .top{
            padding: 15px 15px 0;
            .top-title{
                font-weight: bold;
                position: relative;
                padding-left: 15px;
                margin-left: -15px;
                font-size: 1rem;
            }
            .top-title:before{
                content: " ";
                position:absolute;
                left:0;top:0;bottom:0;width:5px;
                background: @red;
            }
        }
        .row-wrapper{
            margin: 8px;
            .subcard{
                // background: @primary-color;
                // padding: 15px 5px;
                // margin: 5px;
                // border-radius: 5px;
                padding: 15px;
                border-top: 1px solid rgba(255, 255, 255, .2);
                .sub-title{
                    text-align: left;
                    margin-bottom: 15px;
                    .txt{
                        padding-left: 5px;
                    }
                }
                .amount{
                    text-align: right;
                    background: @red;
                    border-radius: 8px;
                    padding: 0 5px;
                    color: @white;
                }
            }
            .subcard:nth-child(odd){
                border-right: 1px solid rgba(255, 255, 255, .2);
            }
        }
        
    }
    .nav-bar{
        padding: 20px;
        margin: 20px 0;
        .title{
            flex: 1;
            text-align: left;
            padding-left: 20px;
        }
    }
}

</style>
