<template>
    <div class="avatar">
        <img :src="url" class="avatar-img" :width="w" :height="h" :style="{borderRadius: radius}">
    </div>
</template>

<script>
    import Identicon from 'identicon.js'
    import Md5 from 'md5'
    export default {
        name: 'Avatar',
        data(){
            return {}
        },
        props: {
            txt: {
                type: String
            },
            w:{
                type: Number,
                default: 50
            },
            h:{
                type: Number,
                default: 50
            },
            radius:{
                type: String,
                default: '50%'
            }
        },
        computed: {
            url(){
                return 'data:image/png;base64,' + new Identicon(Md5(this.txt), 420).toString();
            }
        }
    }
</script>

<style scoped>

</style>